import { motion } from "framer-motion";
// material
import { styled } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Stack,
  Grid,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import {
  varFadeInLeft,
  varFadeInUp,
  varWrapEnter,
} from "../components/animate";
import { useState } from "react";
import { useNavigate } from "react-router";
// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.up("md")]: {
    top: 0,
    left: 0,
    width: "100%",
    // height: '100vh',
    display: "flex",
    // position: 'fixed',
    alignItems: "center",
  },
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    margin: "auto",
    textAlign: "center",
    position: "relative",
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up("md")]: {
      margin: "unset",
    },
  })
);

const QuoteCart = () => {
  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <Container maxWidth="lg">
          <ContentStyle>
            <motion.div variants={varFadeInUp}>
              <Stack spacing={3}>
                <Typography variant="h2" sx={{ color: "#54595F" }}>
                  Quote Cart
                </Typography>
              </Stack>
            </motion.div>

            <Grid container spacing={1} alignItems="center">
              <Grid item md={12} xs={12}>
                <div className="check-booking">
                  <div
                    className="check-booking-result"
                    style={{ marginBottom: 10 }}
                  >
                    Your Shipments
                  </div>
                  <p style={{ textAlign: "start" }}>
                    You have 1 shipment in your cart. You can pay now or add
                    another shipment.
                  </p>
                  <table id="cartItems" className="table">
                    <tbody>
                      <tr className="header">
                        <td colSpan={2}>Service</td>
                        <td colSpan={2}>Collection</td>
                        <td colSpan={2}>Delivery</td>
                        <td colSpan={2}>Transit Cover</td>
                        <td>Cust Ref</td>
                        <td className="quotePrice">Price</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr id="v51B5WFd3rbrb3VwNaiF" className="cartItem">
                        <td className="quoteLogo">
                          <img
                            className="quoteLogoImage"
                            style={{ maxWidth: 120 }}
                            src=".././images/StarTrack.webp"
                          />
                        </td>
                        <td className="quoteService">
                          Interparcel
                          <br />
                          Domestic Express
                        </td>
                        <td className="quoteCollDropImage">
                          <img src=".././images/collection-delivery.webp" />
                        </td>
                        <td className="quoteCollDropText">
                          Collection <br />
                          29/12/2022
                        </td>
                        <td className="quoteDelImage">
                          <img src=".././images/collection-delivery.webp" />
                        </td>
                        <td className="quoteDelText">
                          Agery
                          <br />
                          Australia
                        </td>
                        <td className="quoteCoverImage">
                          <img src=".././images/no-transit-cover.webp" />
                        </td>
                        <td className="quoteCoverText">
                          No Cover <br />
                          Included
                        </td>
                        <td>3445</td>
                        <td className="quotePrice">
                          <div className="quotePriceEx">$613.60</div>
                        </td>
                        <td
                          className="cartItemRemove"
                          title="Remove this shipment"
                        >
                          ×
                        </td>
                      </tr>
                      <tr id="uaL56f1LVRwyJpZAF4eE" className="cartItem">
                        <td className="quoteLogo">
                          <img
                            className="quoteLogoImage"
                            style={{ maxWidth: 120 }}
                            src=".././images/StarTrack.webp"
                          />
                        </td>
                        <td className="quoteService">
                          Interparcel
                          <br />
                          Domestic Express
                        </td>
                        <td className="quoteCollDropImage">
                          <img src=".././images/collection-delivery.webp" />
                        </td>
                        <td className="quoteCollDropText">
                          Collection <br />
                          29/12/2022
                        </td>
                        <td className="quoteDelImage">
                          <img src=".././images/collection-delivery.webp" />
                        </td>
                        <td className="quoteDelText">
                          Amyton
                          <br />
                          Australia
                        </td>
                        <td className="quoteCoverImage">
                          <img src=".././images/no-transit-cover.webp" />
                        </td>
                        <td className="quoteCoverText">
                          No Cover <br />
                          Included
                        </td>
                        <td>3445</td>
                        <td className="quotePrice">
                          <div className="quotePriceEx">$613.60</div>
                        </td>
                        <td
                          className="cartItemRemove"
                          title="Remove this shipment"
                        >
                          ×
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
};

export default QuoteCart;
