import React, { createContext, useContext, useState } from 'react';

const AddressContext = createContext();

export const useAddressContext = () => useContext(AddressContext);

export const AddressProvider = ({ children }) => {
  const [userAddress, setUserAddress] = useState(null);

  const saveUserAddress = (address) => {
    setUserAddress(address);
  };

  return (
    <AddressContext.Provider value={{ userAddress, saveUserAddress }}>
      {children}
    </AddressContext.Provider>
  );
};