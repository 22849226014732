import { motion } from "framer-motion";
// material
import { styled } from "@material-ui/core/styles";
import {
    Button,
    Container,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import {
    varWrapEnter,
} from "../components/animate";
import { useEffect, useState } from "react";
import { json, useNavigate } from "react-router";
import { apiBaseUrl, apiBaseUrlDev } from "../constants";

const RootStyle = styled(motion.div)(({ theme }) => ({
    position: "relative",
    [theme.breakpoints.up("md")]: {
        top: 0,
        left: 0,
        width: "100%",
        // height: '100vh',
        display: "flex",
        // position: 'fixed',
        alignItems: "center",
    },
}));
const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(
    ({ theme }) => ({
        zIndex: 10,
        margin: "auto",
        textAlign: "center",
        position: "relative",
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(15),
        [theme.breakpoints.up("md")]: {
            margin: "unset",
        },
    })
);


const BookingList = () => {
    const baseUrl = process.env.NODE_ENV === 'production' ? apiBaseUrl : apiBaseUrlDev;
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([])
    const getAddressBook = async (searchString) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            searchString: searchString
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                `${baseUrl}/getBookedShipmentList`,
                requestOptions
            );

            const result = await response.json();
            const shortedByName = await result.sort((a, b) => a?.sender_name?.localeCompare(b?.sender_name));
            setTableData(shortedByName)
            console.log("shortedByName", shortedByName);
        } catch (error) {
            console.log("error", error);
        }
    };
    useEffect(() => {
        getAddressBook("");
    }, [])
    // console.log(tableData)

    const [input, setInput] = useState("");

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const searchHandler = (e) => {
        console.log("searchHandler:", e?.target?.value);
        setInput(e?.target?.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        getAddressBook(input)
    };

    return (
        <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
            <Container maxWidth="lg">
                <ContentStyle>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            label="Search"
                            type="text"
                            fullWidth
                            key="search-input"
                            size="small"
                            value={input}
                            onChange={searchHandler}
                            sx={{ mr: 2 }}
                        />
                        <Button variant="contained" onClick={handleSearch}>
                            Search
                        </Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sender Name</TableCell>
                                    <TableCell align="right">Collection Name</TableCell>
                                    <TableCell align="right">Delivery Name</TableCell>
                                    <TableCell align="right">Parcel Details(L*W*H)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    return (
                                        <TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                            <TableCell>{row?.sender_name}</TableCell>
                                            <TableCell align="right">{row?.collection_name}</TableCell>
                                            <TableCell align="right">{row?.delivery_name}</TableCell>
                                            <TableCell align="right">{row?.length+" * "+row?.weight+" * "+row?.height+" = "+((row?.length*row?.weight*row?.height)/1000)+"m cubic"}</TableCell>
                                        </TableRow>

                                    );
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </ContentStyle>
            </Container>
        </RootStyle>
    )
}

export default BookingList