import { motion } from "framer-motion";
// material
import { styled } from "@material-ui/core/styles";
import {
    Box,
    Button,
    Collapse,
    Container,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { useSnackbar } from "notistack5";
import {
    varWrapEnter,
} from "../components/animate";
import { useEffect, useState } from "react";
import { json, useNavigate } from "react-router";
import { apiBaseUrl, apiBaseUrlDev } from "../constants";
import { useAddressContext } from "../contexts/AddressContext";

const RootStyle = styled(motion.div)(({ theme }) => ({
    position: "relative",
    [theme.breakpoints.up("md")]: {
        top: 0,
        left: 0,
        width: "100%",
        // height: '100vh',
        display: "flex",
        // position: 'fixed',
        alignItems: "center",
    },
}));
const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(
    ({ theme }) => ({
        zIndex: 10,
        margin: "auto",
        textAlign: "center",
        position: "relative",
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(15),
        [theme.breakpoints.up("md")]: {
            margin: "unset",
        },
    })
);


const SenderAddressGet = () => {
    const baseUrl = process.env.NODE_ENV === 'production' ? apiBaseUrl : apiBaseUrlDev;
    const navigate = useNavigate();
    const { saveUserAddress } = useAddressContext();
    const [tableData, setTableData] = useState([])
    const getAddressBook = async (searchString) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            searchString: searchString
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                `${baseUrl}/getBookedShipmentList`,
                requestOptions
            );

            const result = await response.json();
            const shortedByName = await result.sort((a, b) => a?.sender_name?.localeCompare(b?.sender_name));
            setTableData(shortedByName)
            console.log("shortedByName", shortedByName);
        } catch (error) {
            console.log("error", error);
        }
    };
    useEffect(() => {
        getAddressBook("");
    }, [])
    // console.log(tableData)

    const [input, setInput] = useState("");
    const [open, setOpen] = useState({});
    const handelAccordion = (index) => {
        setOpen({ ...open, [index]: !open[index] })
    }
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const searchHandler = (e) => {
        console.log("searchHandler:", e?.target?.value);
        setInput(e?.target?.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        getAddressBook(input)
    };
    const selectAddressHandler = (e, row) => {
        e.preventDefault();
        saveUserAddress(row)
        // localStorage.setItem("senderAddress", JSON.stringify(row));
        navigate("/quote/courier-quote")
    }

    return (
        <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
            <Container maxWidth="lg">
                <ContentStyle>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            label="Search"
                            type="text"
                            fullWidth
                            key="search-input"
                            size="small"
                            value={input}
                            onChange={searchHandler}
                            sx={{ mr: 2 }}
                        />
                        <Button variant="contained" onClick={handleSearch}>
                            Search
                        </Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>See more</TableCell>
                                    <TableCell align="right">Sender Name</TableCell>
                                    <TableCell align="right">Collection Name</TableCell>
                                    <TableCell align="right">Delivery Name</TableCell>
                                    <TableCell align="right">Select</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    return (
                                        <>
                                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => handelAccordion(index + page * rowsPerPage)}
                                                    >
                                                        {/* see more */}
                                                        <KeyboardArrowDownIcon sx={{ transform: open[index + page * rowsPerPage] ? "rotate(180deg)" : "rotate(0deg)", transition: ".3s transform" }} />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell align="right">{row?.sender_name}</TableCell>
                                                <TableCell align="right">{row?.collection_name}</TableCell>
                                                <TableCell align="right">{row?.delivery_name}</TableCell>
                                                <TableCell align="right">
                                                    <Button variant="contained" size="small" onClick={(e) => {selectAddressHandler(e, row)}}>
                                                        Select
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                    <Collapse in={open[index + page * rowsPerPage]} timeout="auto" unmountOnExit>
                                                        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
                                                            <Typography variant="h6" sx={{ color: "#637381", marginBottom: "0px", marginTop: "15px" }} gutterBottom component="div">
                                                                Sender Details
                                                            </Typography>
                                                            <Table className="subTable" sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell align="right">Email</TableCell>
                                                                        <TableCell align="right">Phone</TableCell>
                                                                        <TableCell align="right">Company</TableCell>
                                                                        <TableCell align="right">Postal Code</TableCell>
                                                                        <TableCell align="right">Address</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row">
                                                                            {row?.sender_name}
                                                                        </TableCell>
                                                                        <TableCell align="right">{row?.sender_email}</TableCell>
                                                                        <TableCell align="right">{row?.sender_phone}</TableCell>
                                                                        <TableCell align="right">{row?.sender_company}</TableCell>
                                                                        <TableCell align="right">{row?.sender_PostalCode}</TableCell>
                                                                        <TableCell align="right">{`${row?.sender_AddressLine1 ? row?.sender_AddressLine1 + ", " : ""}${row?.sender_Suburb ?? ""} ${row?.sender_StateCode ? row?.sender_StateCode + ", " : ""} Australia`}</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                            <Typography variant="h6" sx={{ color: "#637381", marginBottom: "0px", marginTop: "15px" }} gutterBottom component="div">
                                                                Collection Details
                                                            </Typography>
                                                            <Table className="subTable" sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell align="right">Email</TableCell>
                                                                        <TableCell align="right">Phone</TableCell>
                                                                        <TableCell align="right">Company</TableCell>
                                                                        <TableCell align="right">Postal Code</TableCell>
                                                                        <TableCell align="right">Address</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row">
                                                                            {row?.collection_name}
                                                                        </TableCell>
                                                                        <TableCell align="right">{row?.collection_email}</TableCell>
                                                                        <TableCell align="right">{row?.collection_phone}</TableCell>
                                                                        <TableCell align="right">{row?.collection_company}</TableCell>
                                                                        <TableCell align="right">{row?.collection_PostalCode}</TableCell>
                                                                        <TableCell align="right">{`${row?.collection_AddressLine1 ? row?.collection_AddressLine1 + ", " : ""}${row?.collection_Suburb ?? ""} ${row?.collection_StateCode ? row?.collection_StateCode + ", " : ""} Australia`}</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                            <Typography variant="h6" sx={{ color: "#637381", marginBottom: "0px", marginTop: "15px" }} gutterBottom component="div">
                                                                Delivery Details
                                                            </Typography>
                                                            <Table className="subTable" sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell align="right">Email</TableCell>
                                                                        <TableCell align="right">Phone</TableCell>
                                                                        <TableCell align="right">Company</TableCell>
                                                                        <TableCell align="right">Postal Code</TableCell>
                                                                        <TableCell align="right">Address</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row">
                                                                            {row?.delivery_name}
                                                                        </TableCell>
                                                                        <TableCell align="right">{row?.delivery_email}</TableCell>
                                                                        <TableCell align="right">{row?.delivery_phone}</TableCell>
                                                                        <TableCell align="right">{row?.delivery_company}</TableCell>
                                                                        <TableCell align="right">{row?.delivery_PostalCode}</TableCell>
                                                                        <TableCell align="right">{`${row?.delivery_AddressLine1 ? row?.delivery_AddressLine1 + ", " : ""}${row?.delivery_Suburb ?? ""} ${row?.delivery_StateCode ? row?.delivery_StateCode + ", " : ""} Australia`}</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                            {/* Render the history data here */}
                                                        </div>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </ContentStyle>
            </Container>
        </RootStyle>
    )
}

export default SenderAddressGet