import moment from "moment";
import React from "react";
import { useNavigate } from "react-router";

const SelectServices = () => {
  const navigate = useNavigate();

  const submitBookHandler = () => {
    navigate("/quote/courier-quote");
  };

  const tntDetails = JSON.parse(localStorage.getItem("show_tnt"));
  const tollGroupDetails = JSON.parse(localStorage.getItem("show_tollGroup"));

  return (
    <>
      <div className="check-booking-space">
        {/* <div className="check-booking">
          <div className="check-booking-result">Express Delivery</div>
          <div className="check-booking-text">
            <p>
              Our Express Delivery services offer fast &amp; cost effective
              parcel delivery across Australia.
              <br />
              Some remote areas may take a little longer, so be sure to check
              the estimated delivery dates for each service.
            </p>
          </div>
          <div id="result_STEXPA" className="quoteResult collect">
            <div className="quoteLogo">
              <img
                className="quoteLogoImage"
                src=".././images/StarTrack.webp"
              />
            </div>
            <div className="quoteService">
              StarTrack <br />
              Express ATL
            </div>
            <div className="quoteCollDropImage">
              <img
                src=".././images/collection-delivery.webp"
                className="tippit"
                data-tippit="The next collection date for this service is tomorrow, if booked by 11:00am tomorrow."
              />
            </div>
            <div className="quoteCollDropText">
              <span
                className="tippit"
                data-tippit="The next collection date for this service is tomorrow, if booked by 11:00am tomorrow."
              >
                Collection <br />
                Tomorrow
              </span>
            </div>
            <div className="quoteDelImage">
              <img
                src=".././images/collection-delivery.webp"
                className="tippit"
                data-tippit="Estimated delivery Thursday 29th December by 6:00pm"
              />
            </div>
            <div className="quoteDelText">
              <span
                className="tippit"
                data-tippit="Estimated delivery Thursday 29th December by 6:00pm"
              >
                Delivery <br />
                Thu 29th
              </span>
            </div>
            <div className="quoteBreak1" style={{ clear: "both" }} />
            <div className="quoteCoverImage">
              <img
                src=".././images/no-transit-cover.webp"
                className="tippit"
                data-tippit="No Transit Warranty is available with this service"
              />
            </div>
            <div className="quoteCoverText">
              <span
                className="tippit"
                data-tippit="No Transit Warranty is available with this service"
              >
                No Warranty <br />
                Included
              </span>
            </div>
            <div className="quotePrinterImage">
              <img
                src=".././images/printer-needed.webp"
                className="tippit"
                data-tippit="You will need to print a shipping label"
              />
            </div>
            <div className="quotePrinterText">
              <span
                className="tippit"
                data-tippit="You will need to print a shipping label"
              >
                Printer Needed
              </span>
            </div>
            <div className="quoteBreak3" />
            <div className="quoteInfoImage">
              <img
                src=".././images/info.webp"
                className="tippit"
                data-tippit="Click for more information"
              />
            </div>
            <div className="quoteInfoText">
              <span className="tippit" data-tippit="Click for more information">
                More
                <br />
                Information
              </span>
            </div>
            <div className="quotePrice">
              <div className="quotePriceEx">$62.00</div>
              <div className="quotePriceInc">$68.20 inc GST</div>
              <div className="saved" />{" "}
            </div>
            <div className="quoteBookCell">
              <button className="quoteBookButton" onClick={submitBookHandler}>
                Book
              </button>
            </div>
            <div className="quoteBreak2" style={{ clear: "both" }} />
          </div>
        </div> */}
        <div className="check-booking">
          <div className="check-booking-result">Express Delivery</div>
          <div className="check-booking-text">
            <p>
              Our Express Delivery services offer fast &amp; cost effective
              parcel delivery across Australia.
              <br />
              Some remote areas may take a little longer, so be sure to check
              the estimated delivery dates for each service.
            </p>
          </div>
          {tntDetails && tntDetails[0].children[1].value ? (
            ""
          ) : (
            <>
              {tntDetails &&
                tntDetails.map((data, i) => (
                  <div
                    id="result_STEXPA"
                    className="quoteResult collect"
                    key={i}
                  >
                    <div className="quoteLogo">
                      <img
                        className="quoteLogoImage"
                        src=".././images/StarTrack.webp"
                      />
                    </div>
                    <div className="quoteService">
                      {data?.children[0]?.children[1]?.value}
                    </div>
                    <div className="quoteCollDropImage">
                      <img
                        src=".././images/collection-delivery.webp"
                        className="tippit"
                        data-tippit="The next collection date for this service is tomorrow, if booked by 11:00am tomorrow."
                      />
                    </div>
                    <div className="quoteCollDropText">
                      <span
                        className="tippit"
                        data-tippit="The next collection date for this service is tomorrow, if booked by 11:00am tomorrow."
                      >
                        Collection <br />
                        {data?.children[0]?.children[2]?.value}
                      </span>
                    </div>
                    <div className="quoteDelImage">
                      <img
                        src=".././images/collection-delivery.webp"
                        className="tippit"
                        data-tippit="Estimated delivery Thursday 29th December by 6:00pm"
                      />
                    </div>
                    <div className="quoteDelText">
                      <span
                        className="tippit"
                        data-tippit="Estimated delivery Thursday 29th December by 6:00pm"
                      >
                        Delivery <br />
                        {moment(data?.children[2]?.value).format("ddd Do")}
                      </span>
                    </div>
                    <div className="quoteBreak1" style={{ clear: "both" }} />
                    <div className="quoteCoverImage">
                      <img
                        src=".././images/no-transit-cover.webp"
                        className="tippit"
                        data-tippit="No Transit Warranty is available with this service"
                      />
                    </div>
                    <div className="quoteCoverText">
                      <span
                        className="tippit"
                        data-tippit="No Transit Warranty is available with this service"
                      >
                        No Warranty <br />
                        Included
                      </span>
                    </div>
                    <div className="quotePrinterImage">
                      <img
                        src=".././images/printer-needed.webp"
                        className="tippit"
                        data-tippit="You will need to print a shipping label"
                      />
                    </div>
                    <div className="quotePrinterText">
                      <span
                        className="tippit"
                        data-tippit="You will need to print a shipping label"
                      >
                        Printer Needed
                      </span>
                    </div>
                    <div className="quoteBreak3" />
                    <div className="quoteInfoImage">
                      <img
                        src=".././images/info.webp"
                        className="tippit"
                        data-tippit="Click for more information"
                      />
                    </div>
                    <div className="quoteInfoText">
                      <span
                        className="tippit"
                        data-tippit="Click for more information"
                      >
                        More
                        <br />
                        Information
                      </span>
                    </div>
                    <div className="quotePrice">
                      <div className="quotePriceEx">
                        ${data?.children[1]?.children[0]?.value}
                      </div>
                      {/* <div className="quotePriceInc">$68.20 inc GST</div> */}
                      <div className="saved" />{" "}
                    </div>
                    <div className="quoteBookCell" onClick={submitBookHandler}>
                      <button className="quoteBookButton">Book</button>
                    </div>
                    <div className="quoteBreak2" style={{ clear: "both" }} />
                  </div>
                ))}
            </>
          )}
          <div id="result_STEXPA" className="quoteResult collect">
            <div className="quoteLogo">
              <img
                className="quoteLogoImage"
                src=".././images/StarTrack.webp"
              />
            </div>
            <div className="quoteService">Tnt Group</div>
            <div className="quoteCollDropImage">
              <img
                src=".././images/collection-delivery.webp"
                className="tippit"
                data-tippit="The next collection date for this service is tomorrow, if booked by 11:00am tomorrow."
              />
            </div>
            <div className="quoteCollDropText">
              <span
                className="tippit"
                data-tippit="The next collection date for this service is tomorrow, if booked by 11:00am tomorrow."
              >
                Collection <br />
                {moment(new Date()).format("ddd Do")}
              </span>
            </div>
            <div className="quoteDelImage">
              <img
                src=".././images/collection-delivery.webp"
                className="tippit"
                data-tippit="Estimated delivery Thursday 29th December by 6:00pm"
              />
            </div>
            <div className="quoteDelText">
              <span
                className="tippit"
                data-tippit="Estimated delivery Thursday 29th December by 6:00pm"
              >
                Delivery <br />
                {tollGroupDetails &&
                  tollGroupDetails.TransitTime &&
                  tollGroupDetails.TransitTime.Value}
                {tollGroupDetails &&
                  tollGroupDetails.TransitTime &&
                  tollGroupDetails.TransitTime.UOM}
              </span>
            </div>
            <div className="quoteBreak1" style={{ clear: "both" }} />
            <div className="quoteCoverImage">
              <img
                src=".././images/no-transit-cover.webp"
                className="tippit"
                data-tippit="No Transit Warranty is available with this service"
              />
            </div>
            <div className="quoteCoverText">
              <span
                className="tippit"
                data-tippit="No Transit Warranty is available with this service"
              >
                No Warranty <br />
                Included
              </span>
            </div>
            <div className="quotePrinterImage">
              <img
                src=".././images/printer-needed.webp"
                className="tippit"
                data-tippit="You will need to print a shipping label"
              />
            </div>
            <div className="quotePrinterText">
              <span
                className="tippit"
                data-tippit="You will need to print a shipping label"
              >
                Printer Needed
              </span>
            </div>
            <div className="quoteBreak3" />
            <div className="quoteInfoImage">
              <img
                src=".././images/info.webp"
                className="tippit"
                data-tippit="Click for more information"
              />
            </div>
            <div className="quoteInfoText">
              <span className="tippit" data-tippit="Click for more information">
                More
                <br />
                Information
              </span>
            </div>
            <div className="quotePrice">
              <div className="quotePriceEx">
                $
                {tollGroupDetails &&
                  tollGroupDetails.TotalChargeAmount &&
                  tollGroupDetails.TotalChargeAmount.Value}
              </div>
              {/* <div className="quotePriceInc">$68.20 inc GST</div> */}
              <div className="saved" />{" "}
            </div>
            <div className="quoteBookCell" onClick={submitBookHandler}>
              <button className="quoteBookButton">Book</button>
            </div>
            <div className="quoteBreak2" style={{ clear: "both" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectServices;
