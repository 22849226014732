import React, { useState } from "react";
import "../App.css";
import { styled } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import { varWrapEnter } from "../components/animate";
import { Button, Container, Icon, Stack, TextField } from "@material-ui/core";
import closeFill from "@iconify/icons-eva/close-fill";
import { MIconButton } from "components/@material-extend";
import { useSnackbar } from "notistack5";
import { apiBaseUrl, apiBaseUrlDev } from "../constants";
const RootStyle = styled(motion.div)(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.up("md")]: {
    top: 0,
    left: 0,
    width: "100%",
    // height: '100vh',
    display: "flex",
    // position: 'fixed',
    alignItems: "center",
  },
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    margin: "auto",
    position: "relative",
    justifyContent: "center",
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up("md")]: {
      margin: "unset",
    },
  })
);

const Sucess = () => {
  const baseUrl = process.env.NODE_ENV === 'production' ? apiBaseUrl : apiBaseUrlDev;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const downloadPdf = (e) => {
    e.preventDefault()
    const base64String = localStorage.getItem("labelPdfUrl")
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${base64String}`;
    link.setAttribute('download', 'label.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const downloadManifest = (e) => {
    e.preventDefault()
    const base64String = localStorage.getItem("manifestPdfUrl")
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${base64String}`;
    link.setAttribute('download', 'manifest.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const [input, setInput] = useState("");

  const inputHandler = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const submitDetails = async (e) => {
    e.preventDefault();
    if (!input.email) {
      enqueueSnackbar("please enter a email", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else {
      try {
        var labelUrl = localStorage.getItem("labelPdfUrl");
        var manifestUrl = localStorage.getItem("manifestPdfUrl");

        // Decode the base64 strings to binary format
        var binaryLabelPdfData = atob(labelUrl);
        var binaryManifestPdfData = atob(manifestUrl ?? '');

        // Convert the binary data to Uint8Array to create Blobs
        var labelByteArray = new Uint8Array(binaryLabelPdfData.length);
        var manifestByteArray = new Uint8Array(binaryManifestPdfData.length);

        for (var i = 0; i < binaryLabelPdfData.length; i++) {
          labelByteArray[i] = binaryLabelPdfData.charCodeAt(i);
        }

        for (var i = 0; i < binaryManifestPdfData.length; i++) {
          manifestByteArray[i] = binaryManifestPdfData.charCodeAt(i);
        }

        var labelPdfBlob = new Blob([labelByteArray], { type: 'application/pdf' });
        var manifestPdfBlob = new Blob([manifestByteArray], { type: 'application/pdf' });

        // Create a FormData object and append the email and PDF files
        var formdata = new FormData();
        formdata.append("email", input.email);
        formdata.append("labelPdf", labelPdfBlob, "label.pdf"); // Attach label PDF
        if(manifestUrl){
          formdata.append("manifestPdf", manifestPdfBlob, "manifest.pdf"); // Attach manifest PDF
        }

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        // Send the email with the PDF attachments using the API
        const response = await fetch(baseUrl + "/sendEmailReceipt", requestOptions);
        const result = await response.json();


        if (result?.status) {
          enqueueSnackbar("Email Send Success fully", {
            variant: "success",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
          setInput({ ...input, email: "" });
        } else {
          enqueueSnackbar("There is an error to send email", {
            variant: "error",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
          setInput({ ...input, email: "" });
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  }
  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <Container maxWidth="lg">
          <ContentStyle>
            <div className="sucess-card">
              <div className="card">
                <div
                  style={{
                    borderRadius: 200,
                    height: 200,
                    width: 200,
                    background: "#F8FAF5",
                    margin: "0 auto",
                  }}
                >
                  <i className="checkmark">✓</i>
                </div>
                <h1>Success</h1>
                <p>
                  We received your purchase request;
                  <br /> we'll be in touch shortly!
                </p>
                {
                  localStorage.getItem("labelPdfUrl") &&
                  <>
                    {localStorage.getItem("booking-type") === 'tnt'
                      ? <>
                        <Button fullWidth sx={{ margin: "20px 0px" }} onClick={downloadPdf} variant="contained" color="primary">
                          download Pdf
                        </Button>
                      </>
                      : <>
                        <Button fullWidth sx={{ margin: "20px 0px" }} onClick={downloadPdf} variant="contained" color="primary">
                          download Label
                        </Button>
                        <Button fullWidth sx={{ margin: "20px 0px" }} onClick={downloadManifest} variant="contained" color="primary">
                          download Manifest
                        </Button>
                      </>
                    }

                    <div className="divider">
                      <div></div>
                      <p>or</p>
                      <div></div>
                    </div>
                    <form onSubmit={submitDetails}>
                      <TextField
                        label="Email"
                        fullWidth
                        type={"email"}
                        style={{ margin: "20px 0px" }}
                        onChange={(e) => inputHandler(e)}
                        name="email"
                        value={input.email}
                      />
                      <Button fullWidth type="submit" variant="contained" color="primary" >
                        Send Email
                      </Button>
                    </form>
                  </>
                }
              </div>
            </div>
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
};

export default Sucess;
