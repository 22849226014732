import { motion } from "framer-motion";
// material
import { styled } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Stack,
  Grid,
  TextField,
  MenuItem,
  Button,
  Modal,
  Box,
} from "@material-ui/core";
import { useSnackbar } from "notistack5";
import {
  varFadeInLeft,
  varFadeInUp,
  varWrapEnter,
} from "../components/animate";
import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { MIconButton } from "components/@material-extend";
import axios from "axios";
import urlencode from "urlencode";
import XMLParser from "react-xml-parser";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { apiBaseUrl, apiBaseUrlDev } from "../constants";
import { Link } from "react-router-dom";
import tntImage from "../images/TNT.png"
import tollImage from "../images/toll.png"
import { useAddressContext } from "../contexts/AddressContext";
// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.up("md")]: {
    top: 0,
    left: 0,
    width: "100%",
    // height: '100vh',
    display: "flex",
    // position: 'fixed',
    alignItems: "center",
  },
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    margin: "auto",
    textAlign: "center",
    position: "relative",
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up("md")]: {
      margin: "unset",
    },
  })
);
function inputReducer(state, action) {
  switch (action.type) {
    case 'SET_INPUT':
      return { ...state, [action.field]: action.value };
    case 'BULK_INPUT':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
const CourierAddress = () => {
  const navigate = useNavigate();
  const { userAddress } = useAddressContext();
  const [goods, setGoods] = useState("none");
  const [input, setInput] = useState("");
  const [inputState, dispatchInputState] = useReducer(inputReducer, {});
  const handleInputState = (e) => {
    dispatchInputState({
      type: 'SET_INPUT',
      field: e.target.name,
      value: e.target.value,
    });
  };
  console.log(inputState)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const baseUrl = process.env.NODE_ENV === 'production' ? apiBaseUrl : apiBaseUrlDev;
  // this  area is for address inputs 
  const searchOptions = {
    types: ["address"],
    componentRestrictions: {
      country: ["au"],
    },
  };
  // ** this ares for for getting pickup date 
  const [datesPickup, setDatesPickup] = useState([]);
  const dateFormate = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
  useEffect(() => {
    const today = new Date();
    const dateArray = [];

    let count = 0;
    while (dateArray.length < 3) {
      const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() + count);
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        dateArray.push(date);
      }
      count++;
    }

    setDatesPickup(dateArray);
  }, []);

  const [pickupDate, setPickupDate] = useState("")
  const handleDateChange = (event) => {
    setPickupDate(event.target.value);
  };
  // ** end here

  // ** this area is for earliest time 
  const earliestTimeList = [
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
  ]
  const [earliestTime, setEarliestTime] = useState("");
  const handleEarliestTimeChange = (e) => {
    setEarliestTime(e?.target?.value)
  }
  // ** end here 


  // ** this area is for cutoff time 
  const cutoffTimeList = [
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
  ]
  const [cutoffTime, setCutoffTime] = useState("");
  const handleCutoffTimeChange = (e) => {
    setCutoffTime(e?.target?.value)
  }
  // ** end here 
  useEffect(() => {
    handleSelectCollection(JSON.parse(localStorage.getItem('collect-address')));
    handleSelectDelivery(JSON.parse(localStorage.getItem('delivery-address')));
  }, [])

  useEffect(() => {
    if (userAddress) {
      const row = userAddress;
      // setInput({
      //   ...input,
      //   senderdetils_Telephone: row?.sender_phone,
      //   senderdetils_company: row?.sender_company,
      //   senderdetils_Name: row?.sender_name,
      //   email: row?.sender_email,
      //   collection_Telephone: row?.collection_phone,
      //   collection_company: row?.collection_company,
      //   collection_name: row?.collection_name,
      //   collection_Email: row?.collection_email,
      //   delivery_Telephone: row?.delivery_phone,
      //   delivery_company: row?.delivery_company,
      //   delivery_name: row?.delivery_name,
      //   delivery_Email: row?.delivery_email,
      // })
      const dispatchData = {
        senderdetils_Telephone: row?.sender_phone,
        senderdetils_company: row?.sender_company,
        senderdetils_Name: row?.sender_name,
        email: row?.sender_email,
        collection_Telephone: row?.collection_phone,
        collection_company: row?.collection_company,
        collection_name: row?.collection_name,
        collection_Email: row?.collection_email,
        delivery_Telephone: row?.delivery_phone,
        delivery_company: row?.delivery_company,
        delivery_name: row?.delivery_name,
        delivery_Email: row?.delivery_email,
      }
      dispatchInputState({
        type: 'BULK_INPUT',
        payload: dispatchData,
      });
      handleSelectSender(`${row?.sender_AddressLine1 ? row?.sender_AddressLine1 + ", " : ""}${row?.sender_Suburb ?? ""} ${row?.sender_StateCode ? row?.sender_StateCode + ", " : ""} Australia`)
      handleSelectCollection(`${row?.collection_AddressLine1 ? row?.collection_AddressLine1 + ", " : ""}${row?.collection_Suburb ?? ""} ${row?.collection_StateCode ? row?.collection_StateCode + ", " : ""} Australia`)
      handleSelectDelivery(`${row?.delivery_AddressLine1 ? row?.delivery_AddressLine1 + ", " : ""}${row?.delivery_Suburb ?? ""} ${row?.delivery_StateCode ? row?.delivery_StateCode + ", " : ""} Australia`)
    }
  }, [userAddress])

  // * this area is for sender's address 
  const [senderAddress, setSenderAddress] = useState([]);
  const [senderAddressValue, setSenderAddressValue] = useState("");
  const handleChangeSender = (address) => {
    setSenderAddressValue(address);
  };
  const handleSelectSender = async (address) => {
    setSenderAddressValue(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const result = await getCompleteAddress(latLng)
      if (result) {
        setSenderAddress(result)
        console.log(result)
        // setInput({
        //   ...input,
        //   senderdetils_address2: getValueFromAddress(result, "administrative_area_level_2", "short_name"),
        //   senderdetils_postalCode: getValueFromAddress(result, "postal_code", "short_name")
        // })
        const dispatchData = {
          // senderdetils_address2: getValueFromAddress(result, "administrative_area_level_2", "short_name"),
          senderdetils_postalCode: getValueFromAddress(result, "postal_code", "short_name")
        }
        dispatchInputState({
          type: 'BULK_INPUT',
          payload: dispatchData,
        });
      } else {
        setSenderAddress(results)
      }
    } catch (error) {
      console.error('Error retrieving postal code:', error);
    }
  };
  // * end here 


  // * this area is for delivery's address 
  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [deliveryAddressValue, setDeliveryAddressValue] = useState("");
  const handleChangeDelivery = (address) => {
    setDeliveryAddressValue(address);
  };
  const handleSelectDelivery = async (address) => {
    setDeliveryAddressValue(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const result = await getCompleteAddress(latLng)
      if (result) {
        setDeliveryAddress(result)
        // setInput({
        //   ...input,
        //   delivery_adderss2: getValueFromAddress(result, "administrative_area_level_2", "short_name"),
        //   delivery_postalCode: getValueFromAddress(result, "postal_code", "short_name")
        // })
        const dispatchData = {
          // delivery_adderss2: getValueFromAddress(result, "administrative_area_level_2", "short_name"),
          delivery_postalCode: getValueFromAddress(result, "postal_code", "short_name")
        }
        dispatchInputState({
          type: 'BULK_INPUT',
          payload: dispatchData,
        });
      } else {
        setDeliveryAddress(results)
      }
    } catch (error) {
      console.error('Error retrieving postal code:', error);
    }
  };
  // * end here 


  // * this area is for collection's address 
  const [collectionAddress, setCollectionAddress] = useState([]);
  const [collectionAddressValue, setCollectionAddressValue] = useState("");
  const handleChangeCollection = (address) => {
    setCollectionAddressValue(address);
  };
  const handleSelectCollection = async (address) => {
    setCollectionAddressValue(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const result = await getCompleteAddress(latLng);
      if (result) {
        setCollectionAddress(result);
        // setInput({
        //   ...input,
        //   collection_adderss2: getValueFromAddress(result, "administrative_area_level_2", "short_name"),
        //   collection_postalCode: getValueFromAddress(result, "postal_code", "short_name")
        // })
        const dispatchData = {
          // collection_adderss2: getValueFromAddress(result, "administrative_area_level_2", "short_name"),
          collection_postalCode: getValueFromAddress(result, "postal_code", "short_name")
        }
        dispatchInputState({
          type: 'BULK_INPUT',
          payload: dispatchData,
        });
      } else {
        setCollectionAddress(results);
      }
    } catch (error) {
      console.error('Error retrieving postal code:', error);
    }
  };
  // * end here 

  // this ares is for full address api 
  const getCompleteAddress = async ({ lat, lng }) => {
    const apiKey = 'AIzaSyB6XA94LnLTga4sh3uVO8rd32zTpQru-bk';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK') {
        const addressComponents = data.results;
        return addressComponents

      } else {
        console.log('Error fetching data from the API.');
        return false
      }
    } catch (error) {
      console.error('Error occurred during API request:', error);
      return false
    }
  };
  // end here 

  const handleChange = (e) => {
    setGoods(e.target.value)
  }
  const inputHandler = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const getValueFromAddress = (comp, include, value) => {
    const maxAddressObject = comp.reduce((maxObj, currentObj) => {
      if (currentObj.address_components.length > maxObj.address_components.length) {
        return currentObj;
      } else {
        return maxObj;
      }
    }, comp[0]);
    const valueObj = maxAddressObject.address_components.find(
      (component) => component.types.includes(include)
    )
    const returnValue = valueObj ? valueObj[value] : "";
    return returnValue;
  }

  const decodeString = (zplString) => {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/pdf");

    var formdata = new FormData();
    formdata.append("file", zplString);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch("http://api.labelary.com/v1/printers/8dpmm/labels/4x6/0/>label.pdf", requestOptions)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => {
        const base64String = btoa(
          new Uint8Array(arrayBuffer)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        localStorage.setItem("labelPdfUrl", base64String)
        // navigate("/success")
        setLoading(false)
        // const link = document.createElement('a');
        // link.href = downloadUrl;
        // link.setAttribute('download', 'label.pdf');
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
      })
      .catch(error => {
        console.log('error', error);
        setLoading(false)
      });

  }
  const [loading, setLoading] = useState(false)
  const [tollLabel, setTollLabel] = useState(false)
  const [tollManifest, setTollManifest] = useState(false)
  useEffect(() => {
    if (tollManifest && tollLabel) {
      navigate("/success")
      setLoading(false)
    }
  }, [tollLabel, tollManifest])
  const booking = async (data, url) => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${baseUrl}/${url}`, requestOptions)
      .then(response => response.text())
      .then(result => {
        if (localStorage.getItem('booking-type') === 'tnt') {
          var xmlData = new XMLParser().parseFromString(result);
          if (xmlData?.children[0].children[0].children[0].children[1].children[0].name === "a:ErrorInformation") {
            enqueueSnackbar(xmlData?.children[0].children[0].children[0].children[1].children[0].children[1].value, {
              variant: "error",
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              ),
            });
            setLoading(false)
          } else {
            const pdfBase64data = xmlData?.children[0].children[0].children[0].children[0].children[0].children[0].children[0].children[0]?.value
            localStorage.setItem("labelPdfUrl", pdfBase64data)
            navigate("/success")
            setLoading(false)
          }
        } else {
          const inputString = JSON.parse(result);
          if (inputString?.TollMessage?.Header?.DocumentType === "ErrorMessage") {
            enqueueSnackbar(inputString?.TollMessage?.ErrorMessages?.ErrorMessage[0].ErrorMessage, {
              variant: "error",
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              ),
            });
            setLoading(false)
          } else {
            if (inputString?.status) {
              if (inputString?.type === 'label') {
                localStorage.setItem("labelPdfUrl", inputString?.data);
                setTollLabel(true);
              } else {
                localStorage.setItem("manifestPdfUrl", inputString?.data);
                setTollManifest(true);
              }
            } else {
              enqueueSnackbar("something went wrong please try again letter", {
                variant: "error",
                action: (key) => (
                  <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </MIconButton>
                ),
              });
              setLoading(false)
            }
            // Remove the prefix text before the JSON string
            // const zplCode = inputString?.TollMessage?.ResponseMessages?.ResponseMessage[0].ResponseMessage
            // decodeString(zplCode);
          }

          // enqueueSnackbar(readableString, {
          //   variant: "success",
          //   action: (key) => (
          //     <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          //       <Icon icon={closeFill} />
          //     </MIconButton>
          //   ),
          // });
        }
      })
      .catch(error => {
        console.log('error', error);
        enqueueSnackbar("something went wrong please try again letter", {
          variant: "error",
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });
        setLoading(false)
      });
  }

  const getNextWorkingDay = () => {
    let today = new Date();
    let nextDay = new Date(today);
    nextDay.setDate(nextDay.getDate() + 1);
    if (today.getDay() === 6) { // Saturday
      nextDay.setDate(nextDay.getDate() + 1); // Skip Saturday
    } else if (today.getDay() === 0) { // sunday
      nextDay.setDate(nextDay.getDate()); // Skip Sunday
    }
    else {
      nextDay.setDate(today.getDate());
    }
    return nextDay.toISOString().slice(0, 10);
  };
  const submitDetails = (e) => {
    e.preventDefault();
    if (!inputState.email) {
      enqueueSnackbar("please enter a email", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!inputState.senderdetils_Name) {
      enqueueSnackbar("please enter sender name", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!inputState.senderdetils_company) {
      enqueueSnackbar("please enter sender company name", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!inputState.senderdetils_Telephone) {
      enqueueSnackbar("please enter sender telephone", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!senderAddressValue) {
      enqueueSnackbar("please enter sender Address", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!inputState.collection_name) {
      enqueueSnackbar("please enter collection name", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!inputState.collection_Telephone) {
      enqueueSnackbar("please enter collection telephone", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!collectionAddressValue) {
      enqueueSnackbar("please enter collection address 1", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!inputState.delivery_name) {
      enqueueSnackbar("please enter delivery name", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!inputState.delivery_Telephone) {
      enqueueSnackbar("please enter delivery telephone", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!inputState.delivery_Email) {
      enqueueSnackbar("please enter delivery email", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!deliveryAddressValue) {
      enqueueSnackbar("please enter delivery adress 1", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!inputState.delivery_company) {
      enqueueSnackbar("please enter delivery company", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!inputState.percal_description) {
      enqueueSnackbar("please enter parcel description", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!pickupDate) {
      enqueueSnackbar("please enter pickup date", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!earliestTime) {
      enqueueSnackbar("please enter earliest Time", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else if (!cutoffTime) {
      enqueueSnackbar("please enter Cutoff Time", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } else {
      const parcel_details = localStorage.getItem('parcel-details') ? JSON.parse(localStorage.getItem('parcel-details')) : {};
      let mainData = {
        "serviceCode": localStorage.getItem('booking-id'),
        // this for collection
        "collection_Suburb": getValueFromAddress(collectionAddress, "locality", "short_name"),
        "collection_StateCode": getValueFromAddress(collectionAddress, "administrative_area_level_1", "short_name"),
        "collection_PostalCode": getValueFromAddress(collectionAddress, "postal_code", "short_name"),
        "collection_AddressLine1": getValueFromAddress(collectionAddress, "street_number", "long_name") + " " + getValueFromAddress(collectionAddress, "route", "long_name"),
        "collection_AddressLine2": inputState.collection_adderss2 ?? "",
        "collection_name": inputState.collection_name,
        "collection_email": inputState.collection_Email ?? "",
        "collection_company": inputState.collection_company,
        "collection_phone": inputState.collection_Telephone,
        // this for delivery
        "delivery_Suburb": getValueFromAddress(deliveryAddress, "locality", "short_name"),
        "delivery_StateCode": getValueFromAddress(deliveryAddress, "administrative_area_level_1", "short_name"),
        "delivery_PostalCode": getValueFromAddress(deliveryAddress, "postal_code", "short_name"),
        "delivery_AddressLine1": getValueFromAddress(deliveryAddress, "street_number", "long_name") + " " + getValueFromAddress(deliveryAddress, "route", "long_name"),
        "delivery_AddressLine2": inputState.delivery_adderss2 ?? "",
        "delivery_name": inputState.delivery_name,
        "delivery_email": inputState.delivery_Email,
        "delivery_company": inputState.delivery_company ?? "",
        "delivery_phone": inputState.delivery_Telephone,
        // this for senders
        "sender_Suburb": getValueFromAddress(senderAddress, "locality", "short_name"),
        "sender_StateCode": getValueFromAddress(senderAddress, "administrative_area_level_1", "short_name"),
        "sender_PostalCode": getValueFromAddress(senderAddress, "postal_code", "short_name"),
        "sender_AddressLine1": getValueFromAddress(senderAddress, "street_number", "long_name") + " " + getValueFromAddress(senderAddress, "route", "long_name"),
        "sender_AddressLine2": inputState.senderdetils_address2 ?? "",
        "sender_name": inputState.senderdetils_Name,
        "sender_email": inputState.email,
        "sender_company": inputState.senderdetils_company,
        "sender_phone": inputState.senderdetils_Telephone,
        // item details
        "itemQuantity": parcel_details[0].no ?? '1',
        "width": parcel_details[0].width,
        "length": parcel_details[0].length,
        "height": parcel_details[0].height,
        "volume": (parseInt(parcel_details[0].width) * parseInt(parcel_details[0].length) * parseInt(parcel_details[0].height)) / 1000,
        "weight": parcel_details[0].weight,
        // timing details
        "pickupDateTime": pickupDate?.slice(0, 10),
        "pickupEarliyTime": earliestTime,
        "pickupLatestTime": cutoffTime,
        // description details
        "description": inputState.percal_description,
        "custom_reference": inputState.percal_reference ?? "",
        "percal_Value": inputState.percal_Value ?? "",
        "itemName": "book",
        "itemTypeName": "Pallet"
      }
      if (localStorage.getItem('booking-type') === 'toll') {
        delete mainData["serviceCode"];
        mainData["serviceType"] = "DIRECT";
        mainData["service_code"] = "X";
        mainData["BusinessID"] = "IPEC";
        mainData["AccountCode"] = "80261068";
        mainData["FoodFlag"] = "";
      }
      if (localStorage.getItem('booking-type') === 'tollD') {
        delete mainData["serviceCode"];
        mainData["serviceType"] = "DIRECT";
        mainData["service_code"] = "V";
        mainData["BusinessID"] = "IPEC";
        mainData["AccountCode"] = "80261068";
        mainData["FoodFlag"] = "";
      }
      if (localStorage.getItem('booking-type') === 'toll-nqx') {
        delete mainData["serviceCode"];
        mainData["serviceType"] = "DIRECT";
        mainData["service_code"] = "E";
        mainData["BusinessID"] = "IntermodalSpecialised";
        mainData["AccountCode"] = "H24980";
        mainData["FoodFlag"] = false;
      }
      const url = localStorage.getItem('booking-type') === 'tnt' ? 'tnt-booking' : 'toll-booking';
      console.log(mainData)
      if (localStorage.getItem('booking-type') === 'tnt') {
        booking(mainData, url);
      } else {
        mainData["PrintDocumentType"] = 'Label';
        booking(mainData, url);
        mainData["PrintDocumentType"] = 'Manifest';
        booking(mainData, url);
      }

    }
  };
  const tollData = localStorage.getItem('show_tollGroup') === "undefined" ? "" : JSON.parse(localStorage.getItem('show_tollGroup'));
  const tollDData = localStorage.getItem('show_tollDGroup') === "undefined" ? "" : JSON.parse(localStorage.getItem('show_tollDGroup'));
  const tollNqxData = localStorage.getItem('show_tollNqx') === "undefined" ? "" : JSON.parse(localStorage.getItem('show_tollNqx'));
  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <Container maxWidth="lg">
          <ContentStyle>
            <motion.div variants={varFadeInUp}>
              <Stack spacing={3}>
                <Typography variant="h2" sx={{ color: "#54595F" }}>
                  Select Address
                </Typography>
              </Stack>
            </motion.div>
            <Grid container spacing={1} alignItems="flex-start">
              <Grid item md={9} xs={12}>
                <div className="check-booking">
                  <div
                    className="check-booking-result"
                    style={{ marginBottom: 15 }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                      <p style={{ marginBottom: "0px" }}>Sender Email</p>
                      <Link to="/select-address">
                        <Button variant="contained" size="small" >
                          get Address
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Email Address"
                      fullWidth
                      type={"email"}
                      onChange={(e) => handleInputState(e)}
                      name="email"
                      value={inputState.email}
                    />
                  </motion.div>
                </div>

                <div className="check-booking">
                  <div
                    className="check-booking-result"
                    style={{ marginBottom: 15 }}
                  >
                    Sender Details
                  </div>
                  <p style={{ textAlign: "start", marginBottom: 20 }}>
                    Please fill out your details so we are able to contact you
                    in the unlikely event of any problems.
                  </p>
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Name"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="senderdetils_Name"
                      value={inputState.senderdetils_Name}
                      style={{ marginBottom: 15 }}
                    />
                  </motion.div>
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Company"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="senderdetils_company"
                      value={inputState.senderdetils_company}
                      style={{ marginBottom: 15 }}
                    />
                  </motion.div>
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Telephone"
                      fullWidth
                      type={"number"}
                      onChange={(e) => handleInputState(e)}
                      name="senderdetils_Telephone"
                      value={inputState.senderdetils_Telephone}
                      style={{ marginBottom: 15 }}
                    />
                  </motion.div>
                  <motion.div variants={varFadeInLeft}>
                    <PlacesAutocomplete
                      value={senderAddressValue}
                      onChange={handleChangeSender}
                      onSelect={handleSelectSender}
                      highlightFirstSuggestion={true}
                      searchOptions={searchOptions}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            fullWidth
                            {...getInputProps({
                              placeholder: "Address",
                              className: "location-search-input",
                            })}
                            style={{ marginBottom: 15 }}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: "#fafafa", cursor: "pointer", textAlgin: "center" }
                                : { backgroundColor: "#ffffff", cursor: "pointer", textAlgin: "center" };
                              return (
                                <div
                                  key={index}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>
                                    {suggestion.description}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </motion.div>
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Address 2"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="senderdetils_address2"
                      value={inputState.senderdetils_address2}
                      style={{ marginBottom: 15 }}
                      InputLabelProps={{
                        shrink: inputState.senderdetils_address2 ? true : false, // Keep label above the input when value is present
                      }}
                    />
                  </motion.div>
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Postal Code"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="senderdetils_postalCode"
                      value={inputState.senderdetils_postalCode}
                      style={{ marginBottom: 15 }}
                      InputLabelProps={{
                        shrink: inputState.senderdetils_postalCode ? true : false, // Keep label above the input when value is present
                      }}
                    />
                  </motion.div>

                </div>

                <div className="check-booking">
                  <div
                    className="check-booking-result"
                    style={{ marginBottom: 15 }}
                  >
                    Collection Address
                  </div>
                  <p style={{ textAlign: "start", marginBottom: 10 }}>
                    Where would you like your parcel collected from?
                  </p>
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Name"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="collection_name"
                      value={inputState.collection_name}
                      style={{ marginBottom: 15 }}
                    />
                  </motion.div>
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Company (optional)"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="collection_company"
                      value={inputState.collection_company}
                      style={{ marginBottom: 15 }}
                    />
                  </motion.div>
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Telephone"
                      fullWidth
                      type={"number"}
                      onChange={(e) => handleInputState(e)}
                      name="collection_Telephone"
                      value={inputState.collection_Telephone}
                      style={{ marginBottom: 15 }}
                    />
                  </motion.div>
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Email (optional)"
                      fullWidth
                      type={"email"}
                      onChange={(e) => handleInputState(e)}
                      name="collection_Email"
                      value={inputState.collection_Email}
                      style={{ marginBottom: 15 }}
                    />
                  </motion.div>
                  <motion.div variants={varFadeInLeft}>
                    <PlacesAutocomplete
                      value={collectionAddressValue}
                      onChange={handleChangeCollection}
                      onSelect={handleSelectCollection}
                      highlightFirstSuggestion={true}
                      searchOptions={searchOptions}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            fullWidth
                            style={{ marginBottom: 15 }}
                            {...getInputProps({
                              placeholder: "Address 1",
                              className: "location-search-input",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: "#fafafa", cursor: "pointer", textAlgin: "center" }
                                : { backgroundColor: "#ffffff", cursor: "pointer", textAlgin: "center" };
                              return (
                                <div
                                  key={index}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>
                                    {suggestion.description}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </motion.div>
                  {/* <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Address 1"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="collection_adderss1"
                      value={inputState.collection_adderss1}
                      style={{ marginBottom: 15 }}
                    />
                  </motion.div> */}
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Address 2"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="collection_adderss2"
                      value={inputState.collection_adderss2}
                      style={{ marginBottom: 15 }}
                      InputLabelProps={{
                        shrink: inputState.collection_adderss2 ? true : false, // Keep label above the input when value is present
                      }}
                    />
                  </motion.div>
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Postal Code"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="collection_postalCode"
                      value={inputState.collection_postalCode}
                      style={{ marginBottom: 15 }}
                      InputLabelProps={{
                        shrink: inputState.collection_postalCode ? true : false, // Keep label above the input when value is present
                      }}
                    />
                  </motion.div>
                </div>
                <div className="check-booking">
                  <div
                    className="check-booking-result"
                    style={{ marginBottom: 15 }}
                  >
                    Delivery Address
                  </div>
                  <p style={{ textAlign: "start", marginBottom: 10 }}>
                    Where would you like your parcel delivered?
                  </p>
                  <motion.div variants={varFadeInLeft}>
                    <TextField
                      label="Name"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="delivery_name"
                      value={inputState.delivery_name}
                      style={{ marginBottom: 15 }}
                    />
                    <TextField
                      label="Company"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="delivery_company"
                      value={inputState.delivery_company}
                      style={{ marginBottom: 15 }}
                    />
                    <TextField
                      label="Telephone"
                      fullWidth
                      type={"number"}
                      onChange={(e) => handleInputState(e)}
                      name="delivery_Telephone"
                      value={inputState.delivery_Telephone}
                      style={{ marginBottom: 15 }}
                    />
                    <TextField
                      label="Email"
                      type={"email"}
                      onChange={(e) => handleInputState(e)}
                      name="delivery_Email"
                      value={inputState.delivery_Email}
                      fullWidth
                      style={{ marginBottom: 15 }}
                    />
                    <PlacesAutocomplete
                      value={deliveryAddressValue}
                      onChange={handleChangeDelivery}
                      onSelect={handleSelectDelivery}
                      highlightFirstSuggestion={true}
                      searchOptions={searchOptions}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            fullWidth
                            style={{ marginBottom: 15 }}
                            {...getInputProps({
                              placeholder: "Address 1",
                              className: "location-search-input",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: "#fafafa", cursor: "pointer", textAlgin: "center" }
                                : { backgroundColor: "#ffffff", cursor: "pointer", textAlgin: "center" };
                              return (
                                <div
                                  key={index}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>
                                    {suggestion.description}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {/* <TextField
                      label="Address 1"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="delivery_adderss1"
                      value={inputState.delivery_adderss1}
                      style={{ marginBottom: 15 }}
                    /> */}
                    <TextField
                      label="Address 2"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="delivery_adderss2"
                      value={inputState.delivery_adderss2}
                      style={{ marginBottom: 15 }}
                      InputLabelProps={{
                        shrink: inputState.delivery_adderss2 ? true : false, // Keep label above the input when value is present
                      }}
                    />

                    <TextField
                      label="Postal Code"
                      fullWidth
                      type={"text"}
                      onChange={(e) => handleInputState(e)}
                      name="delivery_postalCode"
                      value={inputState.delivery_postalCode}
                      style={{ marginBottom: 15 }}
                      InputLabelProps={{
                        shrink: inputState.delivery_postalCode ? true : false, // Keep label above the input when value is present
                      }}
                    />
                  </motion.div>
                </div>
                <div className="check-booking">
                  <div
                    className="check-booking-result"
                    style={{ marginBottom: 15 }}
                  >
                    Collection Date & Time
                  </div>
                  <p style={{ textAlign: "start", marginBottom: 20 }}>
                    When would you like your parcel collected?
                  </p>
                  <motion.div variants={varFadeInLeft}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item md={5} xs={12}>
                        <TextField
                          select
                          label="Date"
                          value={pickupDate}
                          onChange={handleDateChange}
                          fullWidth
                        >
                          {datesPickup?.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item?.toISOString()} >{item?.toLocaleDateString('en-US', dateFormate)}</MenuItem>
                            )
                          })}
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          select
                          label="Earliest Time"
                          value={earliestTime}
                          onChange={handleEarliestTimeChange}
                          fullWidth
                        >
                          {earliestTimeList?.map((item) => {
                            return (
                              <MenuItem value={item}>{item}</MenuItem>
                            )
                          })}
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          select
                          label="Latest Time"
                          value={cutoffTime}
                          onChange={handleCutoffTimeChange}
                          fullWidth
                        >
                          {cutoffTimeList?.map((item) => {
                            return (
                              <MenuItem value={item}>{item}</MenuItem>
                            )
                          })}
                        </TextField>
                      </Grid>
                    </Grid>
                  </motion.div>
                </div>
                <div className="check-booking">
                  <div
                    className="check-booking-result"
                    style={{ marginBottom: 15 }}
                  >
                    Parcel Contents
                  </div>
                  <p style={{ textAlign: "start", marginBottom: 10 }}>
                    Please give us a few details about the contents of your
                    parcel.
                  </p>
                  <motion.div variants={varFadeInLeft}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item md={12} xs={12}>
                        {" "}
                        <motion.div variants={varFadeInUp}>
                          <TextField
                            label="Short description of contents"
                            fullWidth
                            multiline
                            rows={3}
                            type={"text"}
                            onChange={(e) => handleInputState(e)}
                            name="percal_description"
                            value={inputState.percal_description}
                            style={{ marginBottom: 15 }}
                          />
                        </motion.div>
                      </Grid>
                      <Grid item md={5} xs={12}>
                        <TextField
                          label="Customer Reference (optional)"
                          fullWidth
                          type={"text"}
                          onChange={(e) => handleInputState(e)}
                          name="percal_reference"
                          value={inputState.percal_reference}
                          style={{ marginBottom: 15 }}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          label="$ Value of contents"
                          fullWidth
                          type={"$ number"}
                          onChange={(e) => handleInputState(e)}
                          name="percal_Value"
                          value={inputState.percal_Value}
                          style={{ marginBottom: 15 }}
                        />
                      </Grid>
                    </Grid>
                  </motion.div>
                </div>
              </Grid>
              {localStorage.getItem('booking-type') === 'tnt' && JSON.parse(localStorage.getItem('show_tnt'))?.map((item, index) => {
                if (item?.children[0]?.children[0]?.value == localStorage.getItem('booking-id')) {
                  return (
                    <Grid item md={3} xs={12} key={index}>
                      <div id="sidebar">
                        <img
                          id="sidebarLogo"
                          src={tntImage}
                        />
                        <div id="service">{item?.children[0]?.children[1]?.value}</div>
                        {/* <div id="description">
                          <p>
                            This service is for deliveries to physical business addresses only. This does not include locations where a business is operated from a home or private residence.
                          </p>
                          <p>
                            Delivery is estimated within 1-2 working days between east coast capital cities. Elsewhere, delivery is within 3-9 working days.
                          </p>
                          <p>
                            A surcharge will apply with the difference in the cost between this service and the non B2B service should the delivery not be to a physical business address.
                          </p>
                          <p>
                            Items sent by Allied Express must be packaged in a cardboard box only. A $14.75 + GST Manual Handling Fee may be applied for fragile or non-conforming items.
                          </p>
                          <p>
                            Authority To Leave - Your package may be left at the delivery address without obtaining a signature.
                          </p>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#f8f8f8",
                            borderRadius: 5,
                            margin: 16,
                            padding: 12,
                            lineHeight: "1.6",
                          }}
                        >
                          This service can carry parcels up to 30kg, with a maximum length of 110cm.
                        </div> */}
                        <div id="total">
                          <div id="totalBreakdown">
                            {/* <div className="totalBreakdown-flex">
                              <p>Courier Services</p>
                              <p>$131.75</p>
                            </div>
                            <div className="totalBreakdown-flex">
                              <p>GST</p>
                              <p>$13.18</p>
                            </div> */}
                            {/* <div className="totalBreakdown-hr"></div> */}
                            <div className="totalBreakdown-flex" style={{ marginTop: 15 }}>
                              <p>TOTAL</p>
                              <span>$ {item?.children[1]?.children[0]?.value}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                } return null
              })}
              {localStorage.getItem('booking-type') === 'toll' &&
                <Grid item md={3} xs={12}>
                  <div id="sidebar">
                    <img
                      id="sidebarLogo"
                      src={tollImage}
                    />
                    <div id="service">Toll service</div>
                    <div id="total">
                      <div id="totalBreakdown">
                        <div className="totalBreakdown-flex">
                          <p>Base Amount</p>
                          <p>$ {tollData?.BaseAmount?.Value}</p>
                        </div>
                        <div className="totalBreakdown-flex">
                          <p>Freight Charge</p>
                          <p>$ {tollData?.FreightCharge?.Value}</p>
                        </div>
                        <div className="totalBreakdown-flex">
                          <p>GST Amount</p>
                          <p>$ {tollData?.GSTAmount?.Value}</p>
                        </div>
                        <div className="totalBreakdown-flex">
                          <p>Toll Extra Service Charge</p>
                          <p>$ {tollData?.TollExtraServiceCharge?.Value}</p>
                        </div>
                        <div className="totalBreakdown-flex">
                          <p>Total Surcharges</p>
                          <p>$ {tollData?.TotalSurcharges?.Value}</p>
                        </div>
                        <div className="totalBreakdown-flex">
                          <p>Total Fees</p>
                          <p>$ {tollData?.TotalFees?.Value}</p>
                        </div>
                        <div className="totalBreakdown-hr"></div>
                        <div className="totalBreakdown-flex" style={{ marginTop: 15 }}>
                          <p>TOTAL</p>
                          <span>$ {tollData?.TotalChargeAmount?.Value}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              }

              {localStorage.getItem('booking-type') === 'tollD' &&
                <Grid item md={3} xs={12}>
                  <div id="sidebar">
                    <img
                      id="sidebarLogo"
                      src={tollImage}
                    />
                    <div id="service">Toll service</div>
                    <div id="total">
                      <div id="totalBreakdown">
                        <div className="totalBreakdown-flex">
                          <p>Base Amount</p>
                          <p>$ {tollDData?.BaseAmount?.Value}</p>
                        </div>
                        <div className="totalBreakdown-flex">
                          <p>Freight Charge</p>
                          <p>$ {tollDData?.FreightCharge?.Value}</p>
                        </div>
                        <div className="totalBreakdown-flex">
                          <p>GST Amount</p>
                          <p>$ {tollDData?.GSTAmount?.Value}</p>
                        </div>
                        <div className="totalBreakdown-flex">
                          <p>Toll Extra Service Charge</p>
                          <p>$ {tollDData?.TollExtraServiceCharge?.Value}</p>
                        </div>
                        <div className="totalBreakdown-flex">
                          <p>Total Surcharges</p>
                          <p>$ {tollDData?.TotalSurcharges?.Value}</p>
                        </div>
                        <div className="totalBreakdown-flex">
                          <p>Total Fees</p>
                          <p>$ {tollDData?.TotalFees?.Value}</p>
                        </div>
                        <div className="totalBreakdown-hr"></div>
                        <div className="totalBreakdown-flex" style={{ marginTop: 15 }}>
                          <p>TOTAL</p>
                          <span>$ {tollDData?.TotalChargeAmount?.Value}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              }
              {localStorage.getItem('booking-type') === 'toll-nqx' &&
                <Grid item md={3} xs={12}>
                  <div id="sidebar">
                    <img
                      id="sidebarLogo"
                      src={tollImage}
                    />
                    <div id="service">Toll NQX service</div>
                    <div id="total">
                      <div id="totalBreakdown">
                        <div className="totalBreakdown-flex">
                          {tollNqxData?.BaseAmount?.Value &&
                            <>
                              <p>Base Amount</p>
                              <p>$ {tollNqxData?.BaseAmount?.Value}</p>
                            </>
                          }
                        </div>
                        <div className="totalBreakdown-flex">
                          {tollNqxData?.FreightCharge?.Value &&
                            <>
                              <p>Freight Charge</p>
                              <p>$ {tollNqxData?.FreightCharge?.Value}</p>
                            </>
                          }
                        </div>
                        <div className="totalBreakdown-flex">
                          {tollNqxData?.GSTAmount?.Value &&
                            <>
                              <p>GST Amount</p>
                              <p>$ {tollNqxData?.GSTAmount?.Value}</p>
                            </>
                          }
                        </div>
                        <div className="totalBreakdown-flex">
                          {tollNqxData?.TollExtraServiceCharge?.Value && <>
                            <p>Toll Extra Service Charge</p>
                            <p>$ {tollNqxData?.TollExtraServiceCharge?.Value}</p>
                          </>}
                        </div>
                        <div className="totalBreakdown-flex">
                          {tollNqxData?.TotalSurcharges?.Value && <>
                            <p>Total Surcharges</p>
                            <p>$ {tollNqxData?.TotalSurcharges?.Value}</p>
                          </>}
                        </div>
                        <div className="totalBreakdown-flex">
                          {tollNqxData?.TotalFees?.Value && <>
                            <p>Total Fees</p>
                            <p>$ {tollNqxData?.TotalFees?.Value}</p>
                          </>}
                        </div>
                        <div className="totalBreakdown-hr"></div>
                        <div className="totalBreakdown-flex" style={{ marginTop: 15 }}>
                          <p>TOTAL</p>
                          <span>$ {tollNqxData?.TotalChargeAmount?.Value}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              }
              <Grid item md={9} xs={12}>
                <Button
                  color="inherit"
                  variant="contained"
                  style={{
                    textAlgin: "center",
                    paddingTop: loading ? '0px' : "6px",
                    paddingBottom: loading ? '0px' : "6px"
                  }}
                  onClick={submitDetails}
                >
                  {loading ? <svg style={{ width: '40px' }} version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xmlSpace="preserve">
                    <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite" />
                    </path>
                  </svg> : "Book"}
                </Button>

              </Grid>
            </Grid>
          </ContentStyle>
        </Container>
      </RootStyle >
    </>
  );
};

export default CourierAddress;
