import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, Button, Stack } from '@material-ui/core';
//
import SvgIconStyle from 'components/SvgIconStyle';
// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'FaceBook', icon: facebookFill },
  { name: 'Google', icon: googleFill },
  { name: 'Linkedin', icon: linkedinFill },
  { name: 'Twitter', icon: twitterFill }
];

const LINKS = [
  {
    headline: 'CONTACT US',
    children: [
      { name: 'GPO Box 1691, Brisbane, Qld, 4001', href: '#' },
      { name: 'Phone: 1300 873 398', href: 'tel:1300 873 398' },
      { name: 'Email: info@fxtlogistics.com', href: 'mailto:info@fxtlogistics.com' }
    ]
  },
  {
    headline: 'INFORMATION',
    children: [
      { name: 'About us', href: 'about-us' },
      { name: 'Contact us', href: 'contact-us' },
      { name: 'Terms & conditions', href: 'terms-conditions' }
    ]
  },
  {
    headline: 'LET US HELP YOU',
    children: [
      { name: 'Prohibited And Restricted', href: 'prohibited-restricted' },
      { name: 'Privacy Policy', href: 'privacy-policy' }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  background: 'url(/images/footer-bg.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%'
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'center' } }}
          spacing={5}
        >
          {LINKS.map((list, index) => {
            const { headline, children } = list;
            if (headline === 'INFORMATION') {
              return (
                <Grid item xs={12} md={4} justifyContent="space-between" key={index}>
                  <Stack key={headline} spacing={2}>
                    <Typography
                      variant="overline"
                      color="white"
                      sx={{ fontFamily: 'Poppins', fontSize: 25, lineHeight: '30px', fontWeight: 600 }}
                    >
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="white"
                        component={RouterLink}
                        sx={{
                          display: 'block',
                          fontWeight: 500,
                          fontSize: 16,
                          lineHeight: '24px',
                          fontFamily: 'Poppins'
                        }}
                      >
                        {link.name}
                      </Link>
                    ))}
                    <form method="POST" action="https://www.myteamge.com/delegate/track?externalSearchQuery=" target="_blank"><input type="hidden" name="auth" value="eyJhbGciOiJSUzUxMiJ9.eyJ1c2VySWQiOiJmMTQ0ODk2YS0zZmZlLTQzNGEtYTBlMS0zYmVmOTUwZmNhM2UiLCJUJlQiOnRydWUsImdlbmVyYXRlRGF0ZSI6IjE3MDI2MDA3OTE3NjYiLCJjdXN0b21OYW1lIjoiMTUtMTItMjNfTXlUZWFtR0VUb2tlbiIsImNlcnRpZmljYXRlTmFtZSI6ImxvY2FsaGNsIiwiQyZDIjp0cnVlLCJ1bmlxdWVJZCI6IjY5YTc3ODg1MDA2Y2ExMmU5NWNmNTEwOTM4MmY1NTVmMjMxODMyZGNmMDc1OThmZTNiZjllMWQ5YWVlYTBjYTUiLCJleHAiOjE3NjU3NTkxOTF9.H4_peLJPsmhhkw8W2pI3LmCescS9mNkPyasYeso9gBb2C2Wjs7L0bWXEeDvxHg8r4Cl85j23CH68HMvwXBqHyfBiZ4lVM3LfbmL_SGCKlIOLDAV0-tVdUa0YGILGj4Tq-j1moNGbQV2RqRxh5Kf-MoZiCkq4YdzBt6wEVGcuZbKTyDwZXS727zEXyZi6iL_aGoMEh_ZFHfflQ1e-vPwQkSQ5B-KuAITnAASF0F6FCEKJmtHCqLgmJizOCfgAjMnUcrNBjVjmVjN_zpZ3ZLsAPaHSyZ6bWUBEauLqi5UBe9f6bIOpCCQhqkTV7YprleYGF2pVS9up9LPxhT8KC_NOTg" /><input type="hidden" name="uuid" value="info@fxtlogistics.com" /> <button type="submit" className='extraLink'>Track</button> </form>
                  </Stack>
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12} md={4} justifyContent="space-between" key={index}>
                  <Stack key={headline} spacing={2}>
                    <Typography
                      variant="overline"
                      color="white"
                      sx={{ fontFamily: 'Poppins', fontSize: 25, lineHeight: '30px', fontWeight: 600 }}
                    >
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="white"
                        component={RouterLink}
                        sx={{
                          display: 'block',
                          fontWeight: 500,
                          fontSize: 16,
                          lineHeight: '24px',
                          fontFamily: 'Poppins'
                        }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                </Grid>
              );
            }
          })}
        </Grid>
      </Container>

      <Divider sx={{ border: '2px solid #ffffff' }} />

      <Typography
        color="white"
        sx={{
          py: 4,
          fontSize: 16,
          fontWeight: 500,
          lineHeight: '24px',
          textAlign: 'center',
          fontFamily: 'Poppins'
        }}
      >
        Copyright @ 2022 FXT Logistics. All Right reserved
      </Typography>
    </RootStyle>
  );
}
