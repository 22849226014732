import axios from "axios";
import { axiosBaseUrl, axiosBaseUrlDev } from "../constants";

// ----------------------------------------------------------------------
const baseURL = process.env.NODE_ENV === 'production' ? axiosBaseUrl : axiosBaseUrlDev ;
const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
